let intialState = {
    openIndividual: false,
    openIndividual1: false,
    dataLoading: true,
    dataLoading1: true,
    dataLoading2: false,
    subjectData: [],
    isloading: true,
    sectionData: [],
    studentList: [],
    centerData: [],
    isStudentLoading: true,
    isFacultyLoading: true,
    isShowChapter: false,
    chapterData: [],
    sectionChapterData: [],
    countLoading: true,
    ishistoryLoading: true,
    isAttendanceLoading: true,
    isSubtopicLoading: true,
    ishLoading: true,
    testsLoading: true,
    quesLoading: true,
    centerTests: [
        {
            "_id": "6784f8fa7f0070070b062909",
            "course": "Diploma In Tool Engineering & Digital Manufacturing (CP01)",
            "course_id": "61335ab9e11e2f5e9098bfc3",
            "total_tests": 72,
            "average_test_score": 2.9609977154541243,
            "total_test_students": 2834
        },
        {
            "_id": "6784f90f7f0070070b06290a",
            "course": "Diploma In Mechatronics Engineering & Smart Factory (CP15)",
            "course_id": "61335b19e11e2f5e9098bfc4",
            "total_tests": 68,
            "average_test_score": 9.376439656883537,
            "total_test_students": 5825
        },
        {
            "_id": "6784f91f7f0070070b06290b",
            "course": "Diploma In Electronics & Embedded System (CP04)",
            "course_id": "61335b4b2abe765e7639f518",
            "total_tests": 53,
            "average_test_score": 10.540647603780547,
            "total_test_students": 5505
        },
        {
            "_id": "6784f9357f0070070b06290c",
            "course": "Diploma In Electrical & Electronics (CP23)",
            "course_id": "61335b812abe765e7639f519",
            "total_tests": 72,
            "average_test_score": 8.411081898167787,
            "total_test_students": 5824
        },
        {
            "_id": "6784f9427f0070070b06290d",
            "course": "Diploma In Computer Engineering & IT Infrastructure (CP08)",
            "course_id": "61335bc1e11e2f5e9098bfc5",
            "total_tests": 44,
            "average_test_score": 2.581462673289347,
            "total_test_students": 2610
        },
        {
            "_id": "6784f94b7f0070070b06290e",
            "course": "Diploma In Information Technology & Data Science (CP09)",
            "course_id": "61335bfaa4d07b5e5ace6035",
            "total_tests": 28,
            "average_test_score": 4.056584200883259,
            "total_test_students": 2610
        },
        {
            "_id": "6784f94c7f0070070b06290f",
            "course": "TOT 3 Months Program",
            "course_id": "6318347e56bf9d15923a4de2",
            "total_tests": 4,
            "average_test_score": 88.26401932012718,
            "total_test_students": 200
        },
        {
            "_id": "6784f94c7f0070070b062910",
            "course": "TOT 1 YR PGD",
            "course_id": "63297cac06c5eaa67f923ac6",
            "total_tests": 0,
            "average_test_score": 0,
            "total_test_students": 0
        },
        {
            "_id": "6784f94c7f0070070b062911",
            "course": "Smart Manufacturing with Industry 4.0",
            "course_id": "646dfa2078e92e5dc75fa11e",
            "total_tests": 0,
            "average_test_score": 0,
            "total_test_students": 0
        },
        {
            "_id": "6784f94c7f0070070b062912",
            "course": "LEGS - Mechatronics",
            "course_id": "67065f9751670bb441d4daa1",
            "total_tests": 0,
            "average_test_score": 0,
            "total_test_students": 0
        },
        {
            "_id": "6784f94c7f0070070b062913",
            "course": "LEGS - Automobile",
            "course_id": "6728a1ab5e606d2ddfbcd458",
            "total_tests": 0,
            "average_test_score": 0,
            "total_test_students": 0
        }
    ],
    programSubjects: []
}

function getSortData(asc, key, arr) {
    if (asc) {
        return arr.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    }
    else {
        return arr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    }
}
export default function reportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Open_Individual':
            stateCopy.openIndividual = true;
            stateCopy.centerObj = action.payload.el;
            stateCopy.type = action.payload.type;
            if (action.payload.type === 'adminsubject') {
                stateCopy.isShowChapter = true;
            }
            return stateCopy;
        case 'Hide_Chapter':
            stateCopy.isShowChapter = false;
            return stateCopy;
        case 'Close_Individual':
            stateCopy.openIndividual = false;
            stateCopy.centerObj = {};
            stateCopy.centerData = stateCopy.centerDataCopy;
            stateCopy.sectionData = stateCopy.sectionDataCopy;
            stateCopy.isShowChapter = false;
            return stateCopy;
        case 'Refresh_Data':
            stateCopy.centerData = stateCopy.centerDataCopy;
            stateCopy.sectionData = stateCopy.sectionDataCopy;
            return stateCopy;
        case 'Refresh_Chapter_Data':
            stateCopy.chapterData = stateCopy.chapterDataCopy;
            stateCopy.sectionChapterData = stateCopy.sectionchapterDataCopy;
            return stateCopy;
        case 'Student_Login_Trends':
            stateCopy.dates = action.payload.dates;
            stateCopy.num_of_logins = action.payload.num_of_logins;
            return stateCopy;
        case 'Student_Score_Trends':
            stateCopy.dates = action.payload.dates;
            stateCopy.score = action.payload.num_of_engagementscore;
            return stateCopy;
        case 'Reset_Student_Loading':
            stateCopy.isStudentLoading = true;
            return stateCopy;
        case 'Student_List':
            stateCopy.studentList = action.payload.result.map(el => [{ name: el.name, program: el.program, section: el.section, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, monthly_no_of_logins: el.monthly_no_of_logins, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_page = action.payload.total_page;
            stateCopy.isStudentLoading = false;
            return stateCopy;
        case 'Get_Scores_History':
            stateCopy.scoreHistory = action.payload;
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case 'Get_Next_Scores_History':
            stateCopy.scoreHistory = stateCopy.scoreHistory.concat(action.payload);
            stateCopy.ishistoryLoading = false;
            return stateCopy;
        case 'Reset_History':
            stateCopy.scoreHistory = [];
            stateCopy.ishistoryLoading = true;

            return stateCopy;
        case 'Reset_Student_Details':
            stateCopy.scoreDetails = '';
            stateCopy.months = [];
            stateCopy.contents = [];
            stateCopy.subjects = [];
            stateCopy.subjectContent = [];
            stateCopy.subject = [];
            stateCopy.complete = [];
            stateCopy.dataLoading = true;
            stateCopy.dataLoading1 = true;
            stateCopy.dataLoading2 = true;
            stateCopy.ishistoryLoading = true;
            stateCopy.isAttendanceLoading = true;
            stateCopy.scoreHistory = [];
            return stateCopy;
        case 'Student_Report':
            stateCopy.scoreDetails = action.payload.data;
            stateCopy.subjects1 = action.payload.subjects;
            stateCopy.subjectContent = action.payload.subjectContent;
            var len = action.payload.data.total_semesters;
            var arr = []
            for (var i = 0; i < len; i++) {
                if (action.payload.data.current_semester === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true,
                        user_id: action.payload.id
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false,
                        user_id: action.payload.id
                    });
                }
            }
            stateCopy.sems = arr;
            stateCopy.semsforTest = arr;
            stateCopy.dataLoading = false;
            return stateCopy;
        case 'Student_Report1':
            stateCopy.months = action.payload.month;
            stateCopy.contents = action.payload.contents;
            const arr1 = [];
            action.payload.logins.dates.forEach(function (v, i) {
                var obj = {};
                obj.date = v;
                obj.login = action.payload.logins.num_of_logins[i];
                arr1.push(obj);
            });
            stateCopy.studentTren = arr1;
            stateCopy.dataLoading1 = false;
            return stateCopy;
        case 'Get_Subject_Completion':
            stateCopy.subject = action.payload.subject2.subject;
            stateCopy.subjectComplete = action.payload.subject2.complete;
            stateCopy.subjectCount = action.payload.subject2.count;
            stateCopy.testComplete = action.payload.test1.complete;
            stateCopy.testCount = action.payload.test1.count;
            stateCopy.activeSub = action.payload.subject2.subject.length ? action.payload.subject2.subject[0].title : '';
            stateCopy.activeSubId = action.payload.subject2.subject.length ? action.payload.subject2.subject[0]._id : '';
            stateCopy.testLoading = false;
            stateCopy.dataLoading2 = false;
            return stateCopy;
        case 'Get_Active_Sem':
            stateCopy.subject = action.payload.subject.subject;
            stateCopy.subjectComplete = action.payload.subject.complete;
            stateCopy.subjectCount = action.payload.subject.count;
            stateCopy.testComplete = action.payload.test.complete;
            stateCopy.testCount = action.payload.test.count;
            stateCopy.activeSub = action.payload.subject.subject.length ? action.payload.subject.subject[0].title : '';
            stateCopy.activeSubId = action.payload.subject.subject.length ? action.payload.subject.subject[0]._id : '';
            stateCopy.sems.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            stateCopy.testLoading = false;
            return stateCopy;
        case 'Reset_Sems':
            stateCopy.testLoading = true;
            stateCopy.chapterLoading = true;
            return stateCopy;
        case 'Reset_Sem_Subject':
            // stateCopy.testList = [];
            // stateCopy.subjectTopics = ;
            stateCopy.chapterLoading = true;
            return stateCopy;
        case 'Change_Subjects':
            stateCopy.activeSub = action.payload.sub;
            stateCopy.activeSubId = action.payload.subId;
            return stateCopy;
        case 'Get_Active_Contents':
            stateCopy.subjectTopics = action.payload;
            // stateCopy.testList = action.payload.testList;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Active_Subject_Topics':
            stateCopy.subjectTopics = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Test_List1':
            stateCopy.testList = action.payload;
            stateCopy.chapterLoading = false;
            return stateCopy;
        case 'Get_Active_Subject_SubTopics':
            stateCopy.subjectTopics.forEach((el, i) => {
                if (i === action.payload.key) {
                    return el.data = action.payload.data
                }
            })
            stateCopy.isSubtopicLoading = false;
            return stateCopy;
        case 'Reset_Subtopics_Data':
            stateCopy.isSubtopicLoading = true;
            return stateCopy;
        case 'Get_Subtopic_Quiz_History':
            stateCopy.quizHistory = action.payload;
            return stateCopy;
        case 'Clear_Quiz_History':
            stateCopy.quizHistory = [];
            return stateCopy;
        case 'Get_Program':
            stateCopy.programs = action.payload.map(el => [{ value: el._id, label: el.program, value1: 'program' }][0]);
            return stateCopy;
        case 'Get_Section_Lists':
            stateCopy.sections = action.payload.map(el => [{ value: el._id, label: el.title, program_id: el.program._id, semester: el.program.semester, value1: 'section' }][0]);
            return stateCopy;

        // sections

        case 'Get_Section_Graph':
            stateCopy.sectionLists = action.payload.sections;
            stateCopy.engagementScore = action.payload.engagement_score_count;
            stateCopy.studentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Section_Data':
            // monthly_avg_logins: el.monthly_avg_logins
            stateCopy.sectionData = action.payload.data.map(el => [{ title: el.title, program: el.program, center: el.center, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, _id: el._id }][0]);
            stateCopy.sectionDataCopy = stateCopy.sectionData;
            return stateCopy;
        case 'Get_Section_Student_Data':
            // monthly_no_of_logins: el.monthly_no_of_logins,
            stateCopy.sectionStudentList = action.payload.result.map(el => [{ name: el.name, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);
            stateCopy.total_student_page = action.payload.total_page;
            return stateCopy;
        case 'Reset_Section_Details':
            stateCopy.sectionStudentList = [];
            stateCopy.total_student_page = [];
            stateCopy.section_logins = [];
            stateCopy.sectionDates = [];
            stateCopy.section_engagement_score = [];
            stateCopy.sectionScoreDates = [];
            return stateCopy;
        case 'Get_Section_Login_Trends':
            stateCopy.section_logins = action.payload.num_of_logins;
            stateCopy.sectionDates = action.payload.dates;
            return stateCopy;
        case 'Get_Section_Score_Trends':
            stateCopy.sectionScoreDates = action.payload.dates;
            stateCopy.section_engagement_score = action.payload.num_of_engagementscore;
            return stateCopy;
        case 'Filter_Section':
            if (action.payload === '') {
                stateCopy.sectionData = stateCopy.sectionDataCopy;
            }
            else {
                const filterResult = stateCopy.sectionDataCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.sectionData = filterResult;
            }
            return stateCopy;
        case 'Sort_Section':
            const data4 = getSortData(action.payload.asc, action.payload.key, stateCopy.sectionData);
            stateCopy.sectionData = data4;
            return stateCopy;

        //programs
        case 'Get_Program_Graph':
            var colors = ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB', '#EF5DA8']
            stateCopy.programStudentGraph = action.payload.result.map((el, i) => [{ name: el.name, y: el.student_count === 0 ? null : el.student_count, color: colors.map(a => a)[i] }][0]);
            return stateCopy;
        case 'Get_Top_Program_Graph':
            stateCopy.topProgramGraph = action.payload.result.map(el => [{ color: el.color, y: el.engagement_score }][0]);
            stateCopy.programName = action.payload.result.map(el => [el.name][0])
            stateCopy.legands = action.payload.result.map(el => [{ name: el.name, color: el.color }][0])
            return stateCopy;
        case 'Get_Program_Data':
            stateCopy.programData = action.payload.data.map(el => [{ program: el.program, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;
        case 'Reset_Program_Details':
            stateCopy.topSemesterGraph = [];
            stateCopy.semNames = [];
            stateCopy.semesterStudentGraph = [];
            return stateCopy;
        case 'Get_Top_Semester_Graph':
            stateCopy.topSemesterGraph = action.payload.data.map(el => [{ color: el.color, y: el.engagement_score }][0]);
            stateCopy.semNames = action.payload.data.map(el => [el.semester][0])
            stateCopy.semLegands = action.payload.data.map(el => [{ name: el.semester, color: el.color }][0])
            return stateCopy;
        case 'Get_Semester_Student_Graph':
            const colors1 = ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB', '#EF5DA8']
            stateCopy.semesterStudentGraph = action.payload.data.map((el, i) => [{ name: el.semester, y: el.student_count === 0 ? null : el.student_count, color: colors1.map(a => a)[i] }][0]);
            return stateCopy;
        case 'Get_Program_Semester_Data':
            stateCopy.semestermData = action.payload.data.map(el => [{ semester: el.semester, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            return stateCopy;
        case 'Sort_Program':
            const data2 = getSortData(action.payload.asc, action.payload.key, stateCopy.programData);
            stateCopy.programData = data2;
            return stateCopy;
        case 'Sort_Program_Semester':
            const data3 = getSortData(action.payload.asc, action.payload.key, stateCopy.semestermData);
            stateCopy.semestermData = data3;
            return stateCopy;
        //centers

        case 'Get_Center_Graph':
            stateCopy.centerList = action.payload.sections;
            stateCopy.centerEngagementScore = action.payload.engagement_score_count;
            stateCopy.centerStudentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Center_Data':
            stateCopy.centerData = action.payload.data.map(el => [{ title: el.title, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins, _id: el._id }][0]);
            stateCopy.centerDataCopy = stateCopy.centerData;
            return stateCopy;
        case 'Get_Center_Program_Graph':
            stateCopy.centerProgramsList = action.payload.programs;
            stateCopy.centerProgramEngagementScore = action.payload.engagement_score_count;
            stateCopy.centerProgramStudentCount = action.payload.student_count;
            return stateCopy;
        case 'Get_Center_Program_Data':
            stateCopy.centerProgramData = action.payload.data.map(el => [{ program: el.program, students: el.students, engagement_score: el.engagement_score, avg_score: el.avg_score ? el.avg_score : 0, monthly_active_users: el.monthly_active_users, monthly_avg_logins: el.monthly_avg_logins }][0]);
            return stateCopy;
        case 'Reset_Center_Details':
            stateCopy.centerProgramsList = [];
            stateCopy.centerProgramEngagementScore = [];
            stateCopy.centerProgramStudentCount = [];
            stateCopy.centerProgramData = [];
            return stateCopy;
        case 'Filter_Center':
            if (action.payload === '') {
                stateCopy.centerData = stateCopy.centerDataCopy;
            }
            else {
                const filterResult = stateCopy.centerDataCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.centerData = filterResult;
            }
            return stateCopy;
        case 'Sort_Center':
            const data = getSortData(action.payload.asc, action.payload.key, stateCopy.centerData)
            stateCopy.centerData = data;
            return stateCopy;
        case 'Sort_Center_Program':
            const data1 = getSortData(action.payload.asc, action.payload.key, stateCopy.centerProgramData)
            stateCopy.centerProgramData = data1
            return stateCopy;
        //subjects

        case 'Get_Subject_Graph':
            stateCopy.subjectList = action.payload.subject;
            stateCopy.subjectAverage = action.payload.average;
            stateCopy.subjectStudent = action.payload.students;
            return stateCopy;
        case 'Get_Subject_Data':
            stateCopy.subjectData = action.payload.data.map(el => [{ title: el.title, program: el.program, semester: el.semester, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, _id: el._id, program_id: el.program_id }][0]);
            stateCopy.isloading = false;
            return stateCopy;
        case 'Get_Next_Subject_Data':
            stateCopy.subjectData = stateCopy.subjectData.concat(action.payload.data.map(el => [{ title: el.title, program: el.program, semester: el.semester, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, _id: el._id, program_id: el.program_id }][0]));
            stateCopy.isloading = false;
            return stateCopy;
        case 'Reset_Subject':
            stateCopy.chapterList = [];
            stateCopy.chapterAverage = [];
            stateCopy.chapterStudent = [];
            stateCopy.chapterData = [];
            return stateCopy;
        case 'Get_Subject_Chapter_Graph':
            stateCopy.chapterList = action.payload.chapter;
            stateCopy.chapterAverage = action.payload.average;
            stateCopy.chapterStudent = action.payload.students;
            return stateCopy;
        case 'Get_Subject_Chapter_Data':
            stateCopy.chapterData = action.payload.data.map(el => [{ title: el.title, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100 }][0]);
            stateCopy.chapterDataCopy = stateCopy.chapterData;
            return stateCopy;
        case 'Search_Chapter':
            if (action.payload === '') {
                stateCopy.chapterData = stateCopy.chapterDataCopy;
            }
            else {
                const filterResult = stateCopy.chapterDataCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.chapterData = filterResult;
            }
            return stateCopy;
        case 'Get_Section_Chapter_Graph':
            stateCopy.sectionChapterList = action.payload.section;
            stateCopy.sectionChapterAverage = action.payload.average;
            stateCopy.sectionChapterStudent = action.payload.students;
            return stateCopy;
        case 'Get_Section_Chapter_Data':
            stateCopy.sectionChapterData = action.payload.data.map(el => [{ title: el.section_title, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, section_id: el.section_id }][0]);
            stateCopy.sectionchapterDataCopy = stateCopy.sectionChapterData;
            return stateCopy;
        case 'GET_CHAPTER_DATA':
            stateCopy.chaptersData = action.payload;
            return stateCopy;
        case 'Search_Section_Chapter':
            if (action.payload === '') {
                stateCopy.sectionChapterData = stateCopy.sectionchapterDataCopy;
            }
            else {
                const filterResult = stateCopy.sectionchapterDataCopy.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.sectionChapterData = filterResult;
            }
            return stateCopy;
        case 'Sort_Subject':
            const data7 = getSortData(action.payload.asc, action.payload.key, stateCopy.subjectData)
            stateCopy.subjectData = data7;
            return stateCopy;
        case 'Reset_Subject_Loading':
            stateCopy.subjectData = [];
            stateCopy.isloading = true;
            return stateCopy;

        //faculty
        case 'Reset_Faculty_Details':
            stateCopy.facultyTren = [];
            stateCopy.facultySubjectData = [];
            stateCopy.contentCount = {};
            stateCopy.countLoading = true;
            stateCopy.scoreHistory = [];
            stateCopy.ishistoryLoading = true;
            stateCopy.isAttendanceLoading = true;
            return stateCopy;
        case 'Get_Faculty_Data':
            // monthly_engagement_score: el.monthly_engagement_score,content: (el.article_added + el.video_added + el.document_added + el.question_added),
            stateCopy.facultyList = action.payload.result.map(el => [{ name: el.name, subject: el.subject, center: el.center, engagement_score: el.engagement_score, monthly_engagement_score: el.monthly_engagement_score, lastLogin: el.lastLogin, _id: el._id, email: el.email }][0]);;
            stateCopy.facultyTotalPage = action.payload.total_page;
            stateCopy.isFacultyLoading = false;
            return stateCopy;
        case 'Reset_Faculty_Loading':
            stateCopy.isFacultyLoading = true;
            return stateCopy;
        case 'Sort_Faculty':
            const data8 = getSortData(action.payload.asc, action.payload.key, stateCopy.facultyList)
            stateCopy.facultyList = data8;
            return stateCopy;
        case 'Get_Faculty_Logins':
            const arr2 = [];
            action.payload.dates.forEach(function (v, i) {
                var obj = {};
                obj.date = v;
                obj.login = action.payload.num_of_logins[i];
                arr2.push(obj);
            });
            stateCopy.facultyTren = arr2;
            return stateCopy;
        case 'Get_Faculty_Subject_Data':
            stateCopy.facultySubjectData = action.payload.data.map(el => [{ title: el.title, program: el.program, semester: el.semester, students: el.students, less_than_35: el.less_than_35, range_35_to_49: el.range_35_to_49, range_50_to_89: el.range_50_to_89, range_90_to_100: el.range_90_to_100, program_id: el.program_id, _id: el._id }][0]);
            return stateCopy;
        case 'Sort_Faculty_Subject':
            const data9 = getSortData(action.payload.asc, action.payload.key, stateCopy.facultySubjectData)
            stateCopy.facultySubjectData = data9;
            return stateCopy;
        case 'Get_Faculty_Content_Count':
            stateCopy.contentCount = action.payload;
            stateCopy.countLoading = false;
            return stateCopy;
        case 'Reset_Faculty_Subject_Loading':
            stateCopy.chapterData = [];
            stateCopy.sectionChapterData = [];
            return stateCopy;
        case 'Faculty_Login_Trends':
            stateCopy.facultydates = action.payload.dates;
            stateCopy.faculty_num_of_logins = action.payload.num_of_logins;
            return stateCopy;
        case 'Faculty_Score_Trends':
            stateCopy.facultydates = action.payload.dates;
            stateCopy.faculty_score = action.payload.num_of_engagementscore;
            return stateCopy;
        case 'Get_Faculty_Engagement_Score':
            stateCopy.months1 = action.payload.months;
            stateCopy.scores = action.payload.scores;
            stateCopy.engagement_score = action.payload.engagement_score;
            return stateCopy;
        case 'Refresh_Tests':
            stateCopy.testsLoading = true;
            return stateCopy;
        case 'Get_Quiz_List':
            stateCopy.quizList = action.payload;
            stateCopy.testsLoading = false;
            return stateCopy;
        case 'Get_Next_Quiz_List':
            stateCopy.quizList = stateCopy.quizList.concat(action.payload);
            stateCopy.testsLoading = false;
            return stateCopy;
        case 'Get_Test_Lists':
            stateCopy.practiceTestList = action.payload;
            stateCopy.testsLoading = false;
            return stateCopy;
        case 'Get_Next_Test_List':
            stateCopy.practiceTestList = stateCopy.practiceTestList.concat(action.payload);
            stateCopy.testsLoading = false;
            return stateCopy;
        case 'GET_TEST_PROGRAM_WISE':
            colors = ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB', '#EF5DA8']
            stateCopy.programWiseTestGraph = action.payload.sort((a, b) => b.average_test_score - a.average_test_score)?.map((el, i) => [{ name: el.course, y: el.total_tests === 0 ? null : el.total_tests, color: colors.map(a => a)[i] }][0]);
            stateCopy.programWiseTestData = action.payload?.map(el => [{ course: el.course, total_tests: el.total_tests, total_test_students: el.total_test_students, average_test_score: (el.average_test_score === 0 ? el.average_test_score : `${el.average_test_score?.toFixed(2)}%`), course_id: el.course_id, _id: el._id }][0]);
            stateCopy.topTestGraph = action.payload
                .sort((a, b) => b.average_test_score - a.average_test_score)
                .map((el, index) => ({
                    ...el,
                    color: colors.map(a => a)[index],
                    y: el.average_test_score,
                    course: el.course
                }));

            return stateCopy;
        case 'GET_PROGRAM_SUBJECTS':
            stateCopy.programWiseSubjectData = action.payload.filter(el => el.total_tests !== 0)?.map(el => [{ name: el.subject_name, semester: el.semester, total_tests: el.total_tests, total_test_students: el.total_test_students, average_test_score: ((el.average_test_score === 0) ? el.average_test_score : `${el.average_test_score?.toFixed(2)}%`), subject_id: el.subject_id, _id: el._id }][0]);
            return stateCopy;
        case 'Get_Question_Lists':
            stateCopy.quesList = action.payload;
            stateCopy.quesLoading = false;
            return stateCopy;
        case 'Get_Test_History':
            // quiz_details.filter(el => { return el.last_attempt?.score <= props.testHistory?.question })
            stateCopy.testHistory = { ...action.payload.data, quiz_details: action.payload.data?.quiz_details.filter(el => { return el.last_attempt?.score <= action.payload?.question }), question: action.payload.question };
            stateCopy.ishLoading = false;
            return stateCopy;
        case 'Refresh_History':
            stateCopy.ishLoading = true;
            return stateCopy;
        case 'Refresh_Questions':
            stateCopy.quesList = [];
            stateCopy.quesLoading = true;
            return stateCopy;
        default:
            return stateCopy;

    }
}