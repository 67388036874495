let intialState = {
    activeTab: 'batch-exams',
    batchExamList: [],
    scheduledExamList: [],
    examList: [],
    batches: [],
    courses: [],
    isListLoading: true,
    scheduleloading: true,
}

export default function manageExamReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case 'Reset_Schedule_List':
            stateCopy.scheduledExamList = [];
            stateCopy.scheduleloading = true;
            return stateCopy;
        case 'Get_Scheduled_Exam_List':
            stateCopy.scheduledExamList = action.payload;
            stateCopy.scheduleloading = false;
            return stateCopy;
        case 'Reset_Exam_List':
            stateCopy.batchExamList = [];
            // stateCopy.scheduleloading = false;
            return stateCopy;
        case 'Get_Batch_Exam_List':
            stateCopy.batchExamList = action.payload;
            stateCopy.scheduleloading = false;
            return stateCopy;
        case 'Edit_Schedule_Exam':
            if ('complete' in action.payload) {
                stateCopy.batchExamList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.complete = action.payload.complete
                    }
                })
            }
            return stateCopy;
        case 'Get_Exam_List':
            stateCopy.examList = action.payload;
            stateCopy.scheduleloading = false;
            return stateCopy;
        case 'Delete_Exam':
            stateCopy.examList = stateCopy.examList.filter(el => el._id !== action.payload._id);
            return stateCopy;
        case 'Set_Active_Btn':
            stateCopy.activeTab = action.payload;
            stateCopy.scheduleloading = true;
            return stateCopy;
        case 'Get_Exam_Marks':
            stateCopy.markList = action.payload;
            stateCopy.markListCopy = action.payload;
            return stateCopy;
        case 'Fill_Marks':
            stateCopy.markList.forEach(element => {
                if (element.student_id === action.payload.id) {
                    element.score = action.payload.mark
                }
            });
            return stateCopy;
        case 'Search_Exam_Student':
            if (action.payload === '') {
                stateCopy.markList = stateCopy.markListCopy;
            }
            else {
                const filterResult = stateCopy.markListCopy.filter(el => { return el.name.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.markList = filterResult;
            }
            return stateCopy;
        default:
            return stateCopy;

    }
}


